import React from 'react'
import './ContactUsPage.css'
import Navbar from './Navbar'
import Footer from './Footer'

export default function ContactUsPage() {
  return (
    <div className="App">
        <Navbar/>
        <div className='contact-form-section'>
        <div className="contact-title">
          <h1>Contact Us</h1>
        </div>
        <div className='contact-in'>
            <div className='contact-map'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.784307438429!2d-0.177067548415074!3d51.49882551905068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760567da220a01%3A0x31911b371c692e86!2sImperial%20College%20London!5e0!3m2!1sen!2suk!4v1667862055454!5m2!1sen!2suk" title="Imperial" width="100%" height="auto" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className='contact-form'>
            <div className='contact-comment'>
              <p>If you have any queries, comments and/or suggestions, 
                  please don’t hesitate to contact us.
              </p>
              <p> You can fill out the form below and we’ll get back to you 
                  as quickly as we can inshaAllah.
              </p>
            </div>
                <form>
                    <input type='text' placeholder='Name' className='form-txt'/>
                    <input type='text' placeholder='Email' className='form-txt' />
                    <textarea placeholder='Message' className='form-txtarea'></textarea>
                    <input type='submit' name='Submit' className='form-btn'></input>
                </form>
            </div>
        </div>
        </div>
        <Footer />
    </div>
  )
}