import LandingPage from "./components/LandingPage";
import CommitteePage from "./components/CommitteePage";
import AcademicRepsPage from "./components/AcademicRepsPage";
import EventsPage from "./components/EventsPage";
import ContactUsPage from "./components/ContactUsPage";
import AlumniContactsPage from "./components/AlumniContactsPage";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="" exact element={<LandingPage />} />
          <Route path="/committee" exact element={<CommitteePage />} />
          <Route path="/academicreps" exact element={<AcademicRepsPage />} />
          <Route path="/events" exact element={<EventsPage />} />
          <Route path="/contact" exact element={<ContactUsPage />} />
          <Route path="/alumni" exact element={<AlumniContactsPage />} />
        </Routes>
      </Router>
    </div>
  );
}
