import React, { useState } from "react";
import "./Navbar.css";
// import Container from 'react-bootstrap/Container';
// import Navbar from 'react-bootstrap/Navbar';
// import NavMenu from 'react-bootstrap/Nav'
// import NavLink from 'react-bootstrap/NavLink'
import { Link } from "react-router-dom";

function NavbarHome() {
  const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-container">
          <div className="logo-title-group">
            <Logo />
            <div className="navbar-title-div">
              <Link to="/" className="navbar-title">
                <h1>STEM Muslims</h1>
              </Link>
            </div>
          </div>
          <div className="navbar-items">
            <div className="menu-icon" onClick={handleClick}>
              <i className={click ? "fa fas fa-times" : "fa fas fa-bars"}></i>
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <NavItem text="Home" to="/" onClick={closeMenu} />
              <NavItem
                text="Meet Our Committee"
                to="/committee"
                onClick={closeMenu}
              />
              <NavItem
                text="Academic Reps"
                to="/academicreps"
                onClick={closeMenu}
              />
              <NavItem
                text="Alumni Contacts"
                to="/alumni"
                onClick={closeMenu}
              />
              <NavItem text="Events" to="/events" onClick={closeMenu} />
              {/* <NavItem text="Contact Us" to="/contact" onClick={closeMenu}/> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

function Logo() {
  return (
    <div className="logo">
      <img
        src="logo-cropped.png"
        alt="Logo"
        // className="navbar-logo img-responsive"
        className="logo-img"
      />
    </div>
  );
}

function NavItem({ text, to, onClick }) {
  const currentPath = window.location.pathname;
  const isActive = currentPath === to;

  return (
    <li className="nav-item">
      <Link
        to={to}
        className={`nav-links ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        {text}
      </Link>
    </li>
  );
}
export default NavbarHome;
