import Navbar from "./Navbar";
import Footer from "./Footer";
import "./EventsPage.css";

export default function EventsPage() {
  return (
    <div className="EventsPage">
      <Navbar />
      <div className="header">
        <p>Events</p>
      </div>
      <div className="launchEvent">
        <div className="imgContainer">
          <img className="launchImg" src="launchEvent.png" alt="dw" />
        </div>
        <div className="launchText">
          <h1 className="title">Launch Event</h1>
          <div className="launchDescContainer">
            <p className="launchDesc">
              lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla
            </p>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="eventTypes">
        <Event imgSrc="deenDevelopersTalk.jpeg" title="Collaborations" />
        <Event imgSrc="consultantPresentation.jpeg" title="Career Talks" />
        <Event imgSrc="hackathonPlanning.jpeg" title="Hackathons" />
        <Event imgSrc="tutorial.png" title="Tutorials" />
        <Event imgSrc="football.jpeg" title="Socials" />
        <Event imgSrc="hackathonPresentation.jpeg" title="Team Activities" />
      </div>
      <Footer />
    </div>
  );
}

function Event({ imgSrc, title }) {
  return (
    <div className="eventType">
      <img className="eventTypeImg" src={imgSrc} alt="dw" />
      <h1 className="title">{title}</h1>
    </div>
  );
}
