import React from "react";
import "./CommitteePage.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import ppldetails from "../components/committees/2023-24.json"; // Adjust the import if necessary

export default function CommitteePage() {
  return (
    <div className="CommitteePage">
      <Navbar />
      <div className="header">
        <p>Meet Our Committee 2023/24!</p>
      </div>
      <div className="committee-head">
        <HeadMember
          name="Nabil Ahmed"
          role="Head Brother"
          year="3rd Year"
          course="Aeronautical Engineering"
          email="nabil.ahmad21@imperial.ac.uk"
          linkedin="https://www.linkedin.com/in/nabil-ahmad-1527631b0"
        />

        <HeadMember
          name="Tabiya Syed"
          role="Head Sister"
          year="3rd Year"
          course="Materials"
          email="tabiya.syed21@imperial.ac.uk"
          linkedin="https://www.linkedin.com/in/tabiya-syed-a64919197"
        />
      </div>

      <div className="committee-members">
        {Object.keys(ppldetails).map((name) => (
          <ComMember
            key={name}
            name={name}
            role={ppldetails[name].role}
            course={ppldetails[name].course}
            year={ppldetails[name].year}
            linkedin={ppldetails[name].linkedin}
            email={ppldetails[name].email}
          />
        ))}
      </div>

      <Footer />
    </div>
  );
}

function HeadMember({ name, role, year, course, email, linkedin }) {
  return (
    <div className="head-member">
      <div className="info-div">
        <p className="member-name">{name}</p>
        <hr />
        <p className="member-role">{role}</p>
        <p className="info-p">
          {year} {course}
        </p>
        <br />
        <b>Get in Touch</b>
        <div className="icons">
          <MailButton email={email} />
          <FontAwesomeIcon className="icon" icon={faLinkedinIn} onClick={() => window.open(linkedin, '_blank')} />
        </div>
      </div>
    </div>
  );
}

function ComMember({ name, role, year, course, email, linkedin }) {
  return (
    <div className="member">
      <div className="info-div">
        <p className="member-name">{name}</p>
        <hr />
        <p className="member-role">{role}</p>
        <p className="info-p">
          {year} Year {course}
        </p>
        <br />
        <b>Get in Touch</b>
        <div className="icons">
          <MailButton email={email} />
          <FontAwesomeIcon className="icon" icon={faLinkedinIn} onClick={() => window.open(linkedin, '_blank')} />
        </div>
      </div>
    </div>
  );
}

function MailButton({ email }) {
  return (
    <Link onClick={() => (window.location = "mailto:" + email)}>
      <FontAwesomeIcon className="icon" icon={faEnvelope} />
    </Link>
  );
}
