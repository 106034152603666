import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>Useful links</h4>
            <ul>
              <li>
                <a href="https://www.theisoc.com/">ISoc Site</a>
              </li>
              <li>
                <a href="/">stemmuslims@hotmail.com</a>
              </li>
            </ul>
          </div>

          <div className="footer-col">
            <h4>Social media</h4>
            <div className="social-links">
              <a href="https://www.instagram.com/stem.muslims/">
                <i className="fa fa-instagram"></i>
              </a>
              <a href="https://www.linkedin.com/company/stem-muslims-imperial-college-london/">
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
          </div>

          <div className="footer-col">
            <h4>Sign up to our newsletter</h4>
            <input
              type="email"
              placeholder="Enter email"
              className="footer-email"
            ></input>
            <button className="footer-email-btn">Sign Up</button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          ⓒ Copyright 2023 - Imperial STEM Muslim Society - Designed by our
          members
        </div>
      </div>
    </footer>
  );
}

/*
<div className = "Footer">
            {
            <div className='footer-link'>
                Sign up to our newsletter!
            </div>
            }
                
        </div>
*/
