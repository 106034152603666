import "./AlumniContactsPage.css";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function AlumniContactsPage() {
  return (
    <>
      <Navbar />
      <div className="AlumniContactsPage">
        <div className="header">
          <h1 className="header-title"> Alumni Contacts </h1>
          <h2 className="header-description"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea </h2>
        </div>
        <div className="alumni-contacts">
          <AlumniContact
            name="Placeholder Name"
            company="Job Center"
            position="Job Seeker"
            graduation="MSci Theoretical Physics - 2025"
            bio="Feel free to ask me about how my career is going or how I graduated in the future"
            email="fariha@email.com"
          />
          <AlumniContact
            name="Placeholder Name"
            company="Job Center"
            position="Job Seeker"
            graduation="MSci Theoretical Physics - 2025"
            bio="Feel free to ask me about how my career is going or how I graduated in the future"
            email="fariha@email.com"
          />
          <AlumniContact
            name="Placeholder Name"
            company="Job Center"
            position="Job Seeker"
            graduation="MSci Theoretical Physics - 2025"
            bio="Feel free to ask me about how my career is going or how I graduated in the future"
            email="fariha@email.com"
          />
          <AlumniContact
            name="Placeholder Name"
            company="Job Center"
            position="Job Seeker"
            graduation="MSci Theoretical Physics - 2025"
            bio="Feel free to ask me about how my career is going or how I graduated in the future"
            email="fariha@email.com"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}


function AlumniContact({ name, company, position, graduation, bio, email }) {
  return (
    <>
      <div className="alumni-card">
        <div className="alumni-card-logo-container">
          {/* <div className="alumni-card-logo" /> */}
        </div>
        <div className="alumni-card-info-container">
          <div className="titles">
            <div>
              <h1 style={{ color: "#557589" }}>{name}</h1>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1 style={{ color: "#557589", fontSize: "25px" }}>{company}<br />  <h5> {position}</h5> </h1>
              <div style={{ width: "20px" }} />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                  <img style={{ position: "relative", width: "60px", height: "60px" }} src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn logo" />
                </a>
              </div>
            </div>

          </div>
          <div style={{ height: "20px" }} />
          <div className="bio-and-grad-container">
            <div className="bio">
              <p>{bio}</p>
            </div>
            <div style={{ width: "100px" }} />
            <div className="graduation">
              <h3 style={{ color: "#557589" }}>{graduation}</h3>
            </div>
          </div>
          <div style={{ height: "20px" }} />
        </div>
      </div>
    </>
  );
}
/*
-----------------------------------------
                Icon          Company
                              Company description
  NAME

  Bio                          Subject 
                              Graduation date (aligned to the right)
-----------------------------------------
*/
