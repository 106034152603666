import React from "react";
import "./AcademicRepsPage.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export default function AcademicRepsPage() {
  // List with Academic Reps details
  const details = {
    "Mohammed Haider Toha": {
      course: "Aeronautical Engineering",
      year: "3rd",
      linkedin: "http://www.linkedin.com/in/mohammedhaidertoha",
      email: "mrh21@ic.ac.uk"
    },
    "Adam Lounis": {
      course: "BioEngineering",
      year: "2nd",
      linkedin:"https://www.linkedin.com/in/adam-lounis-603528211",
      email: "adam.lounis22@imperial.ac.uk",
    },
    // Sam from BMB
    "Daanyaal Sajed": {
      course: "Chemical Engineering",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/daanyaal-sajed-849055207",
      email: "ds622@ic.ac.uk"
    },
    // Idrees from Chem Eng
    "Hassan Al-Mahmood": {
      course: "Chemistry",
      year: "1st",
      linkedin: "https://www.linkedin.com/in/hassan-al-mahmood",
      email: "ha1122@ic.ac.uk"
    },
    "Ifaz Syed-Hussain": {
      course: "Chemistry",
      year: "4th",
      linkedin: "https://www.linkedin.com/in/ifaz-syed-hussain-082054234",
      email: "is120@ic.ac.uk"
    },
    "Hatim Anjary": {
      course: "Civil Engineering",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/hatim-anjary-99aba3253",
      email: "hatim.anjary22@imperial.ac.uk"
    },
    // Ihsaan from Civil
    "Rayan Akhtar": {
      course: "Computing",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/rayan-akhtar",
      email: "ra1422@ic.ac.uk"
    },
    "Daniel Hasan": {
      course: "Design Engineering",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/daniel-hasan-957745229",
      email: "dh1422@ic.ac.uk"
    },
    // Abdullah from DesEng
    "Ilan Iwumbwe": {
      course: "EIE",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/ilan-iwumbwe-62278215a/",
      email: "ii122@ic.ac.uk"
    },
    "Intishar Alam Misbahul": {
      course: "EEE",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/intisharalam/",
      email: "intishar.misbahul22@imperial.ac.uk"
    },
    "Amin Mohamed" : {
      course: "EEE",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/amin-mohamed-2k4/",
      email: "mohamed-amin.mohamed22@imperial.ac.uk"
    },
    "Aahil Jouher": {
      course: "Joint Maths and Computing",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/aahil-jouher",
      email: "aahil.jouher22@imperial.ac.uk"
    },
    // Laith from Materials
    "Ahmed Ali": {
      course: "Mathematics",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/ahmed-ali-562083289/",
      email: "aa6122@ic.ac.uk"
    },
    // Hasan Ali from Maths

    "Tanaf Chowdhury": {
      course: "Mechanical engineering",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/tanaf-chowdhury-818bb9214",
      email: "ttc122@ic.ac.uk"
    },
    "Zain Naseer": {
      course: "Mechanical Engineering",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/znaseer",
      email: "zn322@ic.ac.uk"
    },

    "Areeb Ahmed": {
      course: "Mechanical Engineering",
      year: "3rd",
      linkedin: "https://www.linkedin.com/in/areeb03",
      email: "areeb.ahmed21@imperial.ac.uk"
    },
    
    "Youssef Dahi": {
      course: "Physics",
      year: "2nd",
      linkedin: "https://www.linkedin.com/in/youssef-d-85b2221a5",
      email: "yd1521@ic.ac.uk"
    }
    // add Ahmed Ejaz Physics


  };
  
  
  return (
    <div className="AcademicRepsPage">
      <Navbar />

      <div className="header">
        <p>Our Academic Reps for 2022/23</p>
      </div>

      <div className="committee-members">
        {Object.keys(details).map((name) => (
          <AcademicRep
            name={name}
            course={details[name].course}
            year={details[name].year}
            linkedin={details[name].linkedin}
            email={details[name].email}
          />
        ))}
      </div>

      <Footer />
    </div>
  );
}

function AcademicRep({ name, course, year, linkedin, email }) {
  return (
    <div className="member">
      <div className="info-div">
        <p className="member-name">{name}</p>
        <hr />
        <p className="member-role">{year} Year {course}</p>
        <br />
        <div className="icons">
          <MailButton email={email} className = "icon"/>
          <FontAwesomeIcon className="icon" icon={faLinkedinIn} onClick={() => (window.location = linkedin)} />
        </div>
      </div>
    </div>
  );
}

function MailButton({ email }) {
  return (
    <Link onClick={() => (window.location = "mailto:" + email)}>
      <FontAwesomeIcon className="icon" icon={faEnvelope} />
    </Link>
  );
}
