import "./LandingPage.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import React from "react";

export default function LandingPage() {
  return (
    <div className="App">
      <Navbar />
      {/* <img src = {process.env.PUBLIC_URL+"/welcomeimg.png"} alt = "Welcome Image" className = "welcome-img" /> */}
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      ></link>
      <div className="intro">
        <h2 className="greetings">
          Assalaamu’Alaykum Wa Rahmatullahi Wa Barakatuh
        </h2>
        <h1 className="welcome">Welcome To STEM Muslims</h1>
      </div>

      <div className="info-sect">
        <div className="info-container who-we-are-info">
          <h1 className="info-title who-we-are-title">Who We Are</h1>
          <p className="info-desc who-we-are-desc">
            STEM Muslims is a united network of Muslim students supporting one
            another to achieve success in their academic and career goals. It
            provides a platform for Muslim students to focus on their
            professional development whilst upholding Muslim values.
          </p>
        </div>

        <div className="info-container our-mission-info">
          <h1 className="info-title our-mission-title">Our Mission</h1>
          <p className="info-desc our-mission-desc">
            Our mission is to aid the muslim community in Imperial to ease in
            with university life and attain academic excellence with as little
            impact in their Islamic belief and values. And to guide the young
            people of the Ummah with the help of our diverse network.
          </p>
        </div>

        <div className="info-container our-values-info">
          <h1 className="info-title our-values-title">Our values</h1>
          <p className="info-desc our-values-desc">
            Our key values are <b>Amanah</b> (Trust), <b>Sidq</b> (Honesty) and{" "}
            <b>Ihsaan</b> (Excellence). We hold multiple events throughout the
            year to help guide the brothers and sisters to the right path and
            strengthen their faith and help than enforce our values in
            themselves.
          </p>
        </div>
      </div>

      <ContactUs />

      <div className="sponsor-container">
        <div className="sponsor-in">
          <h1 className="sponsor-title">Our Sponsors</h1>
          <ul>
            <li>
              <img
                src="google.png"
                alt="google"
                className="img-logo-inverted"
              ></img>
            </li>
            <li>
              <img
                src="aecom.png"
                alt="aecom"
                className="img-logo-inverted"
              ></img>
            </li>
            <li>
              <img
                src="apple-logo.png"
                alt="apple"
                className="img-logo-inverted"
              ></img>
            </li>
            <li>
              <img src="ibm.png" alt="ibm" className="img-logo-inverted"></img>
            </li>
            <li>
              <img
                src="microsoft.png"
                alt="microsoft"
                className="img-logo-inverted"
              ></img>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}
/*
  <a href="https://www.flaticon.com/free-icons/google" title="google icons">Google icons created by Freepik - Flaticon</a>
  <a href="https://www.flaticon.com/free-icons/microsoft" title="microsoft icons">Microsoft icons created by Pixel perfect - Flaticon</a>
  <a href="https://www.flaticon.com/free-icons/mac" title="mac icons">Mac icons created by Freepik - Flaticon</a>
  <a href="https://www.flaticon.com/free-icons/ibm" title="IBM icons">IBM icons created by Freepik - Flaticon</a>
  <a href="https://www.flaticon.com/free-icons/aecom" title="aecom icons">Aecom icons created by Freepik - Flaticon</a>
  */

function ContactUs() {
  return (
    <div className="App">

  
        <div className="contact-in">
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.784307438429!2d-0.177067548415074!3d51.49882551905068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760567da220a01%3A0x31911b371c692e86!2sImperial%20College%20London!5e0!3m2!1sen!2suk!4v1667862055454!5m2!1sen!2suk"
              title="Imperial"
              width="100%"
              height="auto"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contact-form">
            <h1 className="form-title">Contact Us</h1>
            <form action="https://formspree.io/f/xkneeoay" method="post">
              <input type="text" name="Name" placeholder="Full Name" className="form-txt" required/>
              <input type="email" name="Email" placeholder="Email" className="form-txt" required/>
              <textarea
                name = "Message"
                placeholder="Message"
                className="form-txtarea"
                required
              ></textarea>
              <input type="submit" className="form-btn"></input>
            </form>
          </div>
        </div>

    </div>
  );
}
